import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  TextField,
} from "@material-ui/core";
import UserService from "../users/services/User.service";

const DeleteConfirm = ({
  open,
  onClose,
  onConfirm,
  requirePasswordConfirmation = true,
  title = "Delete",
  description = "Are you sure you want to delete?",
}) => {
  const [password, setPassword] = useState("");
  const [error, setError] = useState();

  useEffect(() => {
    setPassword("");
    setError();
  }, [open]);

  const onDeleteConfirm = async () => {
    if (requirePasswordConfirmation) {
      try {
        await UserService.verifyUser(password);
        onConfirm();
        onClose();
      } catch (error) {
        setError(
          error && error.message ? error.message : "Something went wrong"
        );
      }
    } else {
      onConfirm();
      onClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>
        {requirePasswordConfirmation && (
          <TextField
            autoFocus
            margin="dense"
            id="name"
            error={error}
            variant="outlined"
            helperText={error && error}
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          disabled={requirePasswordConfirmation && !password.length}
          onClick={onDeleteConfirm}
          color="primary"
          autoFocus
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DeleteConfirm.propTypes = {};

export default DeleteConfirm;
