import { AuthenticationEndPoints, userEndPoints } from "../../../config";
import axios from "../../../utils/axios";

const login = async (data) => {
  try {
    let url = AuthenticationEndPoints.login;

    const response = await axios.post(url, data);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const deleteUserById = async (userId, data) => {
  try {
    let url = userEndPoints.crudUser;
    url = url.replace("{userId}", userId);

    const response = await axios.delete(url);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

const resetUserPassword = async (userId, data) => {
  try {
    let url = userEndPoints.resetPassword;
    url = url.replace("{userId}", userId);

    const response = await axios.put(url, data);
    return response.data.data;
  } catch (error) {
    return Promise.reject(error.response);
  }
};

const blockUser = async (userId, isBlocked) => {
  try {
    let url = userEndPoints.changeUserStatus;
    url = url.replace("{userId}", userId);
    url = url.replace("{isBlocked}", isBlocked);

    const response = await axios.put(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getUsersPaginated = async (data) => {
  try {
    const response = await axios.post(userEndPoints.getUsersPaginated, data);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

const getUser = async () => {
  try {
    const response = await axios.get(userEndPoints.user);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

const createUser = async (data) => {
  try {
    const response = await axios.post(userEndPoints.user, data);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

const verifyUser = async (password) => {
  try {
    const response = await axios.post(userEndPoints.verify, { password });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getRoles = async () => {
  try {
    const response = await axios.get(userEndPoints.roles);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export default {
  deleteUserById,
  resetUserPassword,
  blockUser,
  getUsersPaginated,
  createUser,
  verifyUser,
  getUser,
  getRoles,
  login,
};
