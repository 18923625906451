import React from "react";
import BarChart from "../../../components/graphs/BarChart";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { useStyles } from "../styles";
import { PieChart } from "../../../components/graphs/PieChart";
import DashboardService from "../../project/services/Dashboard.service";
import { useEffect } from "react";
import { Legends } from "../../../components/Legend";
import { useProject } from "../../../hooks/useProject";

export const Charts = ({ selectedLevels }) => {
  const { selectedSubAssetTypes, selectedCategory, selectedAssetType } =
    useProject();
  const [barData, setBarData] = React.useState([]);
  const [chartType, setChartType] = React.useState("valueChart");
  const classes = useStyles();

  useEffect(() => {
    if (selectedLevels.length && selectedCategory?.id) {
      getDashboardDataPenoStatusCount();
    }
  }, [selectedLevels, selectedSubAssetTypes, selectedCategory]);

  const getDashboardDataPenoStatusCount = async () => {
    try {
      let response = await DashboardService.getDashboardDataPenoStatusCount({
        levels: selectedLevels,
        assetTypeId: selectedAssetType?.id,
        assetCategoryId: selectedCategory?.id,
      });
      setBarData(response);
    } catch (err) {}
  };

  return barData.length ? (
    <>
      <div className="input-wrapper toggle-buttons">
        <ToggleButtonGroup
          className="filter-status"
          value={chartType}
          exclusive
          style={{ width: "40%" }}
          onChange={(e, value) => setChartType(value)}
          aria-label="text alignment"
          size="small"
        >
          <ToggleButton
            color="primary"
            value="valueChart"
            aria-label="left aligned"
          >
            Completed Counts
          </ToggleButton>
          <ToggleButton value="percentageChart" aria-label="centered">
            Completed Percentage
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
      {chartType === "valueChart" ? (
        <div id="installation-overview">
          <BarChart data={barData} />
          <Legends
            values={[
              { label: "Total", color: "#2D87BB" },
              { label: "Completed", color: "#64C2A6" },
              { label: "Pending", color: "#FEAE65" },
            ]}
          />
        </div>
      ) : (
        <div id="installation-overview" className={classes.pieChartArea}>
          {barData.map((x, i) => (
            <div style={{ padding: "20px" }}>
              <PieChart
                data={[
                  {
                    status: "completed",
                    value: x.values.completed || 0,
                  },
                  {
                    status: "pending",
                    value: x.values.pending || 0,
                  },
                ]}
                index={i}
                name={x.level}
              />
              {i % 5 === 0 && <br />}
            </div>
          ))}
        </div>
      )}
    </>
  ) : null;
};
