import React from "react";
import {
  IconButton,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import moment from "moment";
import { ToggleButton } from "@material-ui/lab";

export const ClientTable = ({ clientsList, rowActions, onChangeStatus }) => {
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              <strong>Client name</strong>
            </TableCell>
            <TableCell align="center">
              <strong>Projects</strong>
            </TableCell>
            <TableCell align="center">
              <strong>Status</strong>
            </TableCell>
            <TableCell align="right">
              <strong>Created on</strong>
            </TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        {clientsList.map((client, index) => {
          return (
            <TableRow hover>
              <TableCell>{client.name}</TableCell>
              <TableCell align="center">{client.projectCount}</TableCell>
              <TableCell align="center">
                <Tooltip
                  title={client.isActive ? "Disable Client" : "Enable Client"}
                >
                  <ToggleButton
                    value="check"
                    size="small"
                    style={{
                      padding: "3px 7px",
                      fontSize: "0.7125rem",
                      minWidth: "75px",
                      backgroundColor: client.isActive
                        ? "#25d6221f"
                        : "#d622221f",
                    }}
                    selected={client.isActive}
                    onChange={() => onChangeStatus(client)}
                  >
                    {client.isActive ? "Active" : "Inactive"}
                  </ToggleButton>
                </Tooltip>
              </TableCell>

              <TableCell align="right">
                {moment(client.createdAt).format("DD/MM/yyyy hh:mm A")}
              </TableCell>
              <TableCell align="right">
                {rowActions.map((action) => (
                  <IconButton
                    size="small"
                    disabled={action.disabled && action.disabled(client)}
                    onClick={() => action.callback(client, index)}
                  >
                    {action.icon}
                  </IconButton>
                ))}
              </TableCell>
            </TableRow>
          );
        })}
      </Table>
    </TableContainer>
  );
};
