import React from "react";
import Header from "../../layout/Header";
import { ProjectContextProvider } from "../../context/projectContext";
import UserPage from "./User";

const UserLayout = () => {
  return (
    <div className="wrapper">
      <ProjectContextProvider>
        <Header />
        <div className="content-wrapper">
          <div className="content">
            <UserPage />
          </div>
        </div>
      </ProjectContextProvider>
    </div>
  );
};

export default UserLayout;
