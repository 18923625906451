import React, { useState, useEffect } from "react";
import Header from "./Header";
import { ProjectContextProvider } from "../context/projectContext";
import { PersistentDrawerLeft } from "./PersistentDrawer";

const Layout = () => {
  const [openSidebar, setOpenSidebar] = useState(true);
  useEffect(() => {
    window.dispatchEvent(new CustomEvent("resize"));
  }, [openSidebar]);
  return (
    <div className="wrapper">
      <ProjectContextProvider>
        <Header />
        <div className="content-wrapper-layout">
          <PersistentDrawerLeft />
        </div>
      </ProjectContextProvider>
    </div>
  );
};

export default Layout;
