import React, { Fragment, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import AddProjectModal from "../../components/AddProjectModal";
import ProjectUploadService from "./services/ProjectUpload.service";
import Moment from "moment";
import { Alert, AlertTitle } from "@material-ui/lab";
import Snackbar from "@material-ui/core/Snackbar";
import DeleteConfirm from "./DeleteConfirm";
import HistoryIcon from "@material-ui/icons/History";
import { IconButton } from "@material-ui/core";

const headCells = [
  {
    id: "projectName",
    numeric: false,
    disablePadding: false,
    label: "Project Name",
  },
  {
    id: "uploadedFile",
    numeric: false,
    disablePadding: false,
    label: "Uploaded File Name",
  },
  {
    id: "records",
    numeric: false,
    disablePadding: false,
    label: "No of records",
  },
  {
    id: "schema",
    numeric: false,
    disablePadding: false,
    label: "Schema",
  },
  {
    id: "uploadedDate",
    numeric: false,
    disablePadding: false,
    label: "Uploaded on",
  },
  { id: "action", numeric: true, disablePadding: false, label: "" },
];

function EnhancedTableHead() {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
          >
            <TableSortLabel>{headCell.label}</TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme) => ({
  toolBar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  title: {
    flex: "1 1 50%",
  },
  search: {
    padding: "0 4px",
    display: "flex",
    alignItems: "center",
    width: 260,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  iconButton: {
    padding: 10,
  },
  tableWidth: {
    width: "75%",
    margin: "50px auto 0",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  margin: {
    marginRight: theme.spacing(1),
    color: "#cc3300",
  },
  buttonBack: {
    marginBottom: theme.spacing(2),
  },
  loadingDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useStyles();

  const handleUploadFinished = (event) => {
    if (event) {
      props.handleUploadFinished();
    }
  };

  return (
    <Fragment>
      <Toolbar className={classes.toolBar}>
        <div className={classes.title}>
          <h3>Project upload</h3>
        </div>
        <AddProjectModal handleUploadFinished={handleUploadFinished} />
      </Toolbar>
    </Fragment>
  );
};

export default function AddProjectsPage() {
  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [tableData, setTableData] = useState({
    list: [],
    total: 0,
    rollback: false,
    rollbackId: null,
  });
  const [page, setPage] = useState(0);
  const [selectedUpload, setSelectedUpload] = useState("");
  const [openDeleteProjectConfirmation, setOpenDeleteDeleteConfirmation] =
    useState(false);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [alertData, setAlertData] = useState({
    title: "success",
    type: "success",
    message: "success",
  });

  useEffect(() => {
    getUploadHistories();
  }, [rowsPerPage, page]);

  const getUploadHistories = async () => {
    let data = {
      currentPage: page + 1,
      pageLimit: rowsPerPage,
    };

    try {
      let response = await ProjectUploadService.getUploadHistory(data);
      if (response && response.data && response.success) {
        setTableData(response.data);
      }
    } catch (err) {
      setAlertData({
        title: "Error",
        type: "error",
        message: "Server error",
      });
      setSnackBarOpen(true);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value || 5);
    setPage(0);
  };

  const deleteUploadById = async () => {
    try {
      let response = await ProjectUploadService.deleteUploadHistory(
        selectedUpload.id
      );
      if (response && response.success) {
        getUploadHistories();
        setAlertData({
          title: "Success",
          type: "success",
          message: "Successfully rollbacked the upload",
        });
        setSnackBarOpen(true);

        return response.data;
      }
    } catch (error) {
      setAlertData({
        title: "Error",
        type: "error",
        message:
          error.data && error.data.message
            ? error.data.message
            : "Something went wrong",
      });
      setSnackBarOpen(true);
    }
  };

  const handleClose = () => setSnackBarOpen(false);

  const handleUploadFinished = () => getUploadHistories();

  const handleSelectedUpload = (e, upload) => {
    e.preventDefault();
    setSelectedUpload(upload);
    setOpenDeleteDeleteConfirmation(true);
  };

  return (
    <div className={classes.tableWidth}>
      <Paper className={classes.paper} elevation={0}>
        <EnhancedTableToolbar
          handleUploadFinished={handleUploadFinished}
          rollback={tableData.rollback}
          deleteUploadById={deleteUploadById}
        />
        <>
          <TableContainer>
            <Table size="small">
              <EnhancedTableHead classes={classes} />
              <TableBody>
                {tableData?.list &&
                  tableData?.list.map((row, index) => (
                    <TableRow hover>
                      <TableCell component="th">
                        {row.project && row.project.projectName}
                      </TableCell>
                      <TableCell>{row.uploadedFilename}</TableCell>
                      <TableCell>{row.noOfRecords}</TableCell>
                      <TableCell>{row?.fileSchema?.name}</TableCell>
                      <TableCell>
                        {Moment(row.createdAt).format("DD/MM/YYYY - h:mmA")}
                      </TableCell>
                      <TableCell align="right">
                        {!row.resources &&
                          !Boolean(
                            tableData?.list
                              ?.filter((lst, ind) => ind < index)
                              ?.find((lst) => lst.projectId === row.projectId)
                              ?.id
                          ) && (
                            <Tooltip title="Rollback last upload">
                              <IconButton
                                onClick={(e) => handleSelectedUpload(e, row)}
                                aria-label="Rollback last upload"
                                size="small"
                              >
                                <HistoryIcon className={classes.margin} />
                              </IconButton>
                            </Tooltip>
                          )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={tableData.total}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </>
      </Paper>
      <DeleteConfirm
        open={openDeleteProjectConfirmation}
        onClose={() => setOpenDeleteDeleteConfirmation(false)}
        onConfirm={deleteUploadById}
      />

      <Snackbar
        open={snackBarOpen}
        autoHideDuration={2000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={alertData.type} variant="filled">
          <AlertTitle>{alertData.title}</AlertTitle>
          {alertData.message}
        </Alert>
      </Snackbar>
    </div>
  );
}
