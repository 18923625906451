import { ClientEndpoints } from "../../../config";
import axios from "../../../utils/axios";

const getClients = async (isActive) => {
  try {
    let url = `${ClientEndpoints.client}${isActive ? "?isActive=true" : ""}`;
    const response = await axios.get(url);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

const createClient = async (data) => {
  try {
    const response = await axios.post(ClientEndpoints.client, data);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

const deleteClient = async (clientId) => {
  try {
    const response = await axios.delete(
      `${ClientEndpoints.client}/${clientId}`
    );
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

const updateClient = async (clientId, data) => {
  try {
    const response = await axios.put(
      `${ClientEndpoints.client}/${clientId}`,
      data
    );
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export default {
  getClients,
  createClient,
  deleteClient,
  updateClient,
};
