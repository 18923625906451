import React, { useMemo, useState, useEffect } from "react";
import { Button, Drawer } from "@material-ui/core";
import InsertChartOutlinedIcon from "@material-ui/icons/InsertChartOutlined";
import ProjectsIco from "../assets/images/projects.svg";
import { MultiSelect } from "./MultiSelect";
import { FilterDrawer } from "./Drawer";

export const LevelFilter = ({ options, selectedItems, onChange }) => {
  const [open, setOpen] = useState(false);

  const onApply = (selectedOptions) => {
    onChange(selectedOptions);
    setOpen(false);
  };
  return (
    <div>
      <Button
        onClick={(e) => setOpen(true)}
        style={{ width: 200 }}
        variant="contained"
        color="primary"
        disableElevation
        size="large"
        startIcon={<img src={ProjectsIco} />}
      >
        Levels
        {selectedItems.length != 0 && (
          <span className="bubble">{selectedItems.length}</span>
        )}
      </Button>
      <FilterDrawer
        open={open}
        selectedValues={selectedItems}
        handleDrawerClose={() => setOpen(false)}
        title={"Levels"}
        items={options}
        onApply={onApply}
        displayName="level"
      />
      {/* <Drawer anchor="top" open={open} onClose={() => setOpen(false)}>
        <div className="drawer-content-top">
          <h2>
            <InsertChartOutlinedIcon /> Levels
          </h2>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "100%",
              }}
            >
              <MultiSelect
                options={levelOptions}
                onChange={(options) => setSelectedOptions(options)}
                selectedItems={selectedOptions}
                label="Levels"
              />
            </div>
            <div style={{ marginLeft: "20px" }}>
              <Button
                onClick={onApply}
                variant="contained"
                color="primary"
                disabled={selectedOptions.length === 0}
                disableElevation
                size="medium"
              >
                Apply
              </Button>
            </div>
          </div>
        </div>
      </Drawer> */}
    </div>
  );
};
