import React, { useState, useCallback } from "react";
import { Card } from "./Card";
import update from "immutability-helper";
import DashboardService from "./services/Dashboard.service";

export const DragableLevels = ({
  levels,
  setLevels,
  onChange,
  buildingId,
  selectedLevels,
}) => {
  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = levels[dragIndex];
      const updatedlevels = update(levels, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragCard],
        ],
      });

      const levelOrder = updatedlevels.map((value, i) => {
        return { id: value.id, order: i + 1 };
      });
      updateLevelOrder({ levelOrder });
      setLevels(updatedlevels);
    },
    [levels]
  );

  const updateLevelOrder = async ({ levelOrder }) => {
    try {
      let response = await DashboardService.reOrderLevel({
        buildingId,
        levelOrder,
      });
    } catch (err) {}
  };

  return (
    <>
      <div>
        {levels &&
          levels.map((level, index) => {
            return (
              <Card
                key={level.id}
                id={level.id}
                index={index}
                level={level}
                selected={selectedLevels.find((x) => x == level.id)}
                onChange={() => onChange(level.id, index)}
                moveCard={moveCard}
              />
            );
          })}
      </div>
    </>
  );
};
