import "date-fns";
import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import ResetIco from "../assets/images/reset.svg";
import ProjectService from "../pages/project/services/Project.service";
import InsertChartOutlinedIcon from "@material-ui/icons/InsertChartOutlined";
import { FilterDrawer } from "../components/Drawer";
import { DateFilter } from "../components/DateFilter";
import DashboardService from "../pages/project/services/Dashboard.service";
import { AutoComplete } from "../components/AutoComplete";
import { FilterButton } from "../components/FilterButton";
import { useProject } from "../hooks/useProject";
import clientService from "../pages/project/services/clientService";

const Sidebar = () => {
  const [projectsList, setProjectsList] = useState([]);
  const [clientsList, setClientsList] = useState([]);
  const [projectAssetCategories, setProjectAssetCategories] = useState([]);
  const [openedFilter, setOpenedFilter] = useState(null);

  const {
    selectedProject,
    setSelectedProject,
    selectedClient,
    setSelectedClient,
    selectedTab,
    selectedSubAssetTypes,
    setSelectedSubAssetTypes,
    selectedBuildings,
    setSelectedBuildings,
    projectAssetTypes,
    projectBuildings,
    resetFilters,
    selectedCategory,
    setSelectedCategory,
    selectedAssetType,
    setSelectedAssetType,
    subAssetTypes,
  } = useProject();

  useEffect(() => {
    getClients();
  }, []);

  useEffect(() => {
    selectedClient?.id && getProjects(selectedClient.id);
  }, [selectedClient]);

  useEffect(() => {
    selectedProject?.id && getProjectAssetCategories(selectedProject.id);
  }, [selectedProject]);

  const resetAll = (resetProject, resetClient) => {
    if (resetProject) {
      setProjectsList([]);
      setSelectedProject(null);
    }
    if (resetClient) {
      setSelectedClient(null);
    }
    resetFilters();
  };

  const getClients = async () => {
    const clients = await clientService.getClients(true);
    setClientsList(clients);
  };

  const getProjects = async (clientId) => {
    const projects = await ProjectService.getProjects({ clientId });
    setProjectsList(projects);
  };

  const getProjectAssetCategories = async (projectId) => {
    const assetCategories = await ProjectService.getProjectAssetCategories(
      projectId
    );
    setProjectAssetCategories(assetCategories);
    assetCategories.length == 1 && onCategorySelect(assetCategories[0]);
  };

  const onCategorySelect = (assetCategory) => {
    assetCategory
      ? setSelectedCategory({
          id: assetCategory.id,
          assetCategory: assetCategory.assetCategory,
        })
      : setSelectedCategory(null);
  };

  const onAssetTypeSelect = (assetType) => {
    assetType
      ? setSelectedAssetType({
          id: assetType.id,
          assetType: assetType.assetType,
        })
      : setSelectedAssetType(null);
  };

  const disableOption = selectedTab === 2 || !selectedProject;

  const onClientChange = (value) => {
    setSelectedClient(value);
    resetAll(true);
  };

  return (
    <aside>
      <div>
        <div className="results">
          <div className="filter-title">
            <InsertChartOutlinedIcon /> Select a project and view analytics
          </div>
          <AutoComplete
            value={selectedClient}
            handleChange={onClientChange}
            options={clientsList}
            label="Search Client"
            size="small"
          />

          <AutoComplete
            value={selectedProject}
            handleChange={(newValue) => setSelectedProject(newValue)}
            options={projectsList}
            optionLabel="projectName"
            label="Search Project"
            disabled={!selectedClient}
            size="small"
          />
          <AutoComplete
            value={selectedCategory}
            handleChange={(newValue) => onCategorySelect(newValue)}
            options={projectAssetCategories}
            label="Select Asset Category"
            optionLabel="assetCategory"
            disabled={!selectedProject}
            size="small"
          />
          <AutoComplete
            value={selectedAssetType}
            handleChange={(newValue) => onAssetTypeSelect(newValue)}
            options={projectAssetTypes}
            label="Select Asset Type"
            optionLabel="assetType"
            disabled={!selectedProject}
            size="small"
          />
        </div>
        <div>
          <FilterButton
            onClick={() => setOpenedFilter("BUILDING")}
            disabled={disableOption}
            label="Buildings"
            count={selectedBuildings.length}
          />

          <FilterButton
            onClick={() => setOpenedFilter("SUB_ASSET_TYPE")}
            disabled={disableOption}
            label="Sub Asset Types"
            count={selectedSubAssetTypes.length}
          />
        </div>
      </div>

      <DateFilter projectId={selectedProject?.id} disabled={disableOption} />

      <div className="button-wrapper">
        <Button
          onClick={() => resetAll(true, true)}
          variant="outlined"
          disableElevation
          size="medium"
        >
          <img className="mr-5" src={ResetIco} /> Reset
        </Button>
      </div>
      <FilterDrawer
        open={openedFilter == "SUB_ASSET_TYPE"}
        selectedValues={selectedSubAssetTypes}
        handleDrawerClose={() => setOpenedFilter(null)}
        title={"Sub Asset Type"}
        items={subAssetTypes}
        onApply={setSelectedSubAssetTypes}
        displayName="subAssetType"
      />
      <FilterDrawer
        open={openedFilter == "BUILDING"}
        selectedValues={selectedBuildings}
        displayName="building"
        handleDrawerClose={() => setOpenedFilter(null)}
        title={"Buildings"}
        items={projectBuildings}
        onApply={setSelectedBuildings}
      />
    </aside>
  );
};

export default Sidebar;
