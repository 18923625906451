import { BASE_API, DownloadEndPoints } from "../../config";
import axios from "../../utils/axios";

axios.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("token");
    config.headers.Authorization = token;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export const getBuildingLevels = async (projectId) => {
  try {
    let url = BASE_API + DownloadEndPoints.listBuildingsAndLevels;
    url = url.replace("{projectId}", projectId);

    const response = await axios.get(url);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const downloadAssets = async (data) => {
  try {
    const response = await axios.post(
      `${BASE_API}${DownloadEndPoints.downloadFiles}/get-key`,
      data
    );
    window.open(
      `${BASE_API}${DownloadEndPoints.downloadFiles}/${response.data.data.id}`
    );
  } catch (error) {
    throw error;
  }
};

export const downloadAssetsExcel = async ({
  projectId,
  assetIds = [],
  categoryId,
}) => {
  try {
    const response = await axios.post(
      `${BASE_API}${DownloadEndPoints.downloadFiles}/get-key`,
      { projectId, assetIds, categoryId }
    );
    let url = BASE_API + DownloadEndPoints.downloadExcelFiles;
    window.open(`${url}/${response.data.data.id}`);
  } catch (error) {
    throw error;
  }
};
