import { ToggleButton } from "@material-ui/lab";
import React, { useEffect, useMemo, useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import useAuth from "../../hooks/useAuth";

export const ItemTypes = {
  CARD: "card",
};
export const Card = ({ level, id, index, moveCard, onChange, selected }) => {
  const { isAdmin } = useAuth();

  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    type: ItemTypes.CARD,
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag, preview] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id: id, index, title: level.level };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: isAdmin,
  });
  const opacity = isDragging ? 0 : 1;
  const backgroundColor = isDragging && "rgb(88 80 235 / 45%)";
  const border = isDragging && "1px dashed rgba(0, 0, 0, 0.6)";
  const containerStyle = useMemo(
    () => ({ backgroundColor, border, opacity }),
    [backgroundColor, border, opacity]
  );

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, []);

  drag(drop(ref));
  return (
    <>
      <ToggleButton
        data-handler-id={handlerId}
        style={containerStyle}
        ref={ref}
        onChange={onChange}
        selected={selected}
      >
        <span>{level.level}</span>
      </ToggleButton>
    </>
  );
};
