import { Paper, Tab, Tabs } from "@material-ui/core";
import React from "react";
import { useStyles } from "./styles";
import { useProject } from "../../hooks/useProject";
import { TabPanel } from "./components/TabPanel";
import { AssetTypeChips } from "./components/AssetTypeChips";
import DownloadLayout from "../downloads/DownloadLayout";
import ProjectTable from "../project/Project";
import { NoRecords } from "../../components/NoRecords";
import Dashboard from "./components/Dashboard";

const DashboardHome = () => {
  const classes = useStyles();
  const {
    selectedProject,
    setProjectStatus,
    selectedTab,
    setSelectedTab,
    selectedBuildings,
    selectedCategory,
    selectedAssetType,
  } = useProject();

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const canShowData =
    selectedProject?.id && selectedCategory?.id && selectedAssetType?.id;

  return (
    <div className={classes.root}>
      <Paper
        elevation={0}
        className="top-tab"
        style={{ justifyContent: "flex-end", display: "flex" }}
      >
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          style={{ maxWidth: 500 }}
          centered
        >
          <Tab label="Dashboard" />
          <Tab label="Data" />
          <Tab label="Downloads" />
        </Tabs>
      </Paper>
      {!selectedProject ? (
        <div className="tab-cnt">
          <NoRecords />
        </div>
      ) : (
        <>
          <ProjectHeader
            selectedBuildings={selectedBuildings}
            selectedProject={selectedProject}
            showHeader={selectedTab !== 2}
          />
          <AssetTypeChips show={selectedTab !== 2} />
          <div className="tab-cnt">
            {canShowData ? (
              <TabContent {...{ selectedTab, selectedProject }} />
            ) : (
              <NoRecords />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export const ProjectHeader = ({
  selectedProject,
  selectedBuildings,
  showHeader,
}) => {
  return (
    <div className="p-name">
      <h2>
        {selectedProject?.projectName}
        {!!selectedBuildings.length && showHeader && (
          <div className="sl-label">
            <span className="badge">{selectedBuildings.length}</span> Buildings
            Selected
          </div>
        )}
      </h2>
    </div>
  );
};

export const TabContent = React.memo(({ selectedTab }) => {
  return (
    <>
      <TabPanel value={selectedTab} index={0}>
        <Dashboard />
      </TabPanel>
      <TabPanel value={selectedTab} index={1}>
        <ProjectTable />
      </TabPanel>
      <TabPanel value={selectedTab} index={2}>
        <DownloadLayout />
      </TabPanel>
    </>
  );
});

export default DashboardHome;
