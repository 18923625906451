export const BASE_API = process.env.REACT_APP_API_HOST + "/api/v1";

export const AuthenticationEndPoints = {
  login: "/auth/login",
  logout: "/auth/logout",
};

export const ClientEndpoints = {
  client: "/client",
};

export const userEndPoints = {
  user: "/user",
  crudUser: "/user/{userId}",
  getUsersPaginated: "/user/list",
  changeUserStatus: "/user/change-status/{userId}/{isBlocked}",
  resetPassword: "/user/reset-password/{userId}",
  verify: "/user/verify",
  roles: "/user/roles",
};

export const ProjectEndPoints = {
  projectsPaginated: "/project/paginated",
  assetTypes: "/project/assetTypes",
  subAssetTypes: "/project/subAssetTypes",
  projects: "/project",
  buildings: "/project/buildings",
  csvUpload: "/project/upload/{projectName}/{clientId}/{categoryId}/{schemaId}",
  assetData: "/project/asset-data",
  crudPenetration: "/project/{projectId}/asset/{assetId}",
  projectAssetTypes: "/project/projectAssetTypes",
  isUniqueAssetId: "/project/asset/isUnique/{assetId}",
  categories: "/project/category",
  schema: "/project/schema",
  projectCategories: "/project/category/{projectId}",
};

export const DashboardEndPoints = {
  dashboard: "/dashboard/{projectId}",
  assetStatusSummary: "/dashboard/asset-status-summary",
  reorderLevels: "/dashboard/reorder-levels",
  overviewProgress: "/dashboard/overview-progress",
  weeklyProgress: "/dashboard/weekly-progress",
  buildingLevels: "/dashboard/building-levels",
};

export const UploadHistoryEndPoints = {
  uploadHistory: "/dashboard/upload-history",
  delete: "/dashboard/upload-history/{id}",
};

export const DownloadEndPoints = {
  downloadFiles: "/asset/download",
  downloadExcelFiles: "/asset/download/excel",
  listBuildingsAndLevels: "/download/list-buildings-and-levels/{projectId}",
};

export const assetEndpoints = {
  asset: "/asset",
  schema: "/asset/schema",
  assetComment: "/asset/comment",
  assetImage: "/asset/image/",
  formDetails: "/asset/{assetId}/form-details",
};
