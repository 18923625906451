import { assetEndpoints } from "../../../config";
import axios from "../../../utils/axios";

const createPenetration = async (projectId, data) => {
  try {
    let url = assetEndpoints.asset;
    url = url.replace("{projectId}", projectId);

    const response = await axios.post(url, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const updatePenetration = async (data) => {
  try {
    let url = assetEndpoints.asset;

    const response = await axios.put(url, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const updateFormDetails = async (data, assetId) => {
  try {
    let url = assetEndpoints.formDetails;
    url = url.replace("{assetId}", assetId);

    const response = await axios.put(url, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const deletePenetrationList = async (data) => {
  try {
    let url = assetEndpoints.asset;

    const response = await axios.delete(url, {
      data,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const deleteImages = async (imageKeys) => {
  try {
    let url = assetEndpoints.assetImage;

    const response = await axios.delete(url, {
      data: { imageKeys },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const addComment = async (assetId, data) => {
  try {
    let url = `${assetEndpoints.asset}/${assetId}`;

    const response = await axios.patch(url, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const fetchSchema = async (projectId, SubAssetTypeId) => {
  try {
    let url = `${assetEndpoints.schema}/${SubAssetTypeId}/${projectId}`;

    const response = await axios.get(url);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

const getAssetData = async (assetId) => {
  try {
    let url = `${assetEndpoints.asset}/${assetId}`;

    const response = await axios.get(url);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

const uploadAssetImage = async (assetId, formData) => {
  try {
    let url = `${assetEndpoints.assetImage}${assetId}`;

    const response = await axios.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export default {
  createPenetration,
  updatePenetration,
  updateFormDetails,
  deletePenetrationList,
  addComment,
  fetchSchema,
  deleteImages,
  uploadAssetImage,
  getAssetData,
};
