import { Box } from "@material-ui/core";

export function TabPanel(props) {
  const { children, value, index, show, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={!show && value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {(value === index || show) && (
        <div className="tab-wrap">
          <Box>{children}</Box>
        </div>
      )}
    </div>
  );
}
