import React, { useState, createContext } from "react";
import moment from "moment";
import { useSessionStorage } from "../helper/storage";
import { useEffect } from "react";
import ProjectService from "../pages/project/services/Project.service";

// Create Context Object
export const ProjectContext = createContext();

// Create a provider for components to consume and subscribe to changes
export const ProjectContextProvider = (props) => {
  const [selectedProject, setSelectedProject] = useSessionStorage(
    "selectedProject",
    {}
  );

  const [selectedClient, setSelectedClient] = useSessionStorage(
    "selectedClient",
    {}
  );

  const [selectedCategory, setSelectedCategory] = useSessionStorage(
    "selectedCategory",
    {}
  );

  const [selectedAssetType, setSelectedAssetType] = useSessionStorage(
    "selectedAssetType",
    {}
  );

  const [projectStatus, setProjectStatus] = useSessionStorage(
    "projectStatus",
    "all"
  );

  const [searchPenetration, setSearchPenetration] = useSessionStorage(
    "searchPenetration",
    ""
  );

  const [selectedProjectPenetration, setSelectedProjectPenetration] = useState(
    {}
  );

  const [projectAssetTypes, setProjectAssetTypes] = useState([]);
  const [subAssetTypes, setSubAssetTypes] = useState([]);
  const [projectBuildings, setProjectBuildings] = useState([]);
  const [isDateFilterEnabled, setIsDateFilterEnabled] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [fromDate, setFromDate] = useState(moment());
  const [toDate, setToDate] = useState(moment());
  const [selectedSubAssetTypes, setSelectedSubAssetTypes] = useState([]);
  const [selectedBuildings, setSelectedBuildings] = useState([]);

  useEffect(() => {
    selectedProject?.id && getProjectAssetTypesAndBuildings(selectedProject.id);
    resetFilters();
  }, [selectedProject]);

  useEffect(async () => {
    if (selectedCategory?.id && selectedProject?.id) {
      const assetTypes = await ProjectService.getProjectAssetTypes(
        selectedProject.id,
        selectedCategory?.id
      );
      setProjectAssetTypes(assetTypes);
      selectedAssetType?.id &&
        assetTypes.find((x) => x.id == selectedAssetType?.id) &&
        setSelectedAssetType(selectedAssetType);
    }
  }, [selectedCategory, selectedProject]);

  useEffect(() => {
    if (selectedAssetType?.id && selectedProject?.id) {
      getSubAssetTypes(selectedAssetType.id, selectedProject.id);
    } else {
      setSubAssetTypes([]);
      setSelectedSubAssetTypes([]);
    }
  }, [selectedAssetType, selectedProject]);

  const getProjectAssetTypesAndBuildings = async (projectId) => {
    const buildings = await ProjectService.getBuildings({ projectId });

    setProjectBuildings(buildings);
    setSelectedBuildings(buildings.map((x) => x.id));
  };

  const getSubAssetTypes = async (selectedAssetTypeId, projectId) => {
    const subAssetTypes = await ProjectService.getSubAssetTypes(
      selectedAssetTypeId,
      projectId
    );
    setSubAssetTypes(subAssetTypes);
    setSelectedSubAssetTypes(subAssetTypes.map((x) => x.id));
  };

  const resetFilters = () => {
    setProjectAssetTypes([]);
    setProjectBuildings([]);
    setSelectedSubAssetTypes([]);
    setSelectedBuildings([]);
    setSelectedCategory(null);
    setSelectedAssetType(null);
    setFromDate(moment().startOf("day"));
    setToDate(moment().endOf("day"));
  };

  return (
    <ProjectContext.Provider
      value={{
        selectedProject,
        setSelectedProject,
        projectStatus,
        setProjectStatus,
        fromDate,
        setFromDate,
        toDate,
        setToDate,
        isDateFilterEnabled,
        setIsDateFilterEnabled,
        selectedTab,
        setSelectedTab,
        selectedClient,
        setSelectedClient,
        selectedSubAssetTypes,
        setSelectedSubAssetTypes,
        selectedBuildings,
        setSelectedBuildings,
        searchPenetration,
        setSearchPenetration,
        selectedProjectPenetration,
        setSelectedProjectPenetration,
        projectAssetTypes,
        setProjectAssetTypes,
        projectBuildings,
        setProjectBuildings,
        resetFilters,
        selectedCategory,
        setSelectedCategory,
        selectedAssetType,
        setSelectedAssetType,
        subAssetTypes,
      }}
    >
      {props.children}
    </ProjectContext.Provider>
  );
};
